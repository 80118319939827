import {graphql} from "gatsby";
import React, {useState} from "react";
import Changelog from "../templates/Changelog";
import fetch from "../lib/fetch";
import {monthNames} from "../lib/utils";

const formatDate = dateStr => {
  const date = new Date(dateStr);
  return `${date.getDate()} ${monthNames[date.getMonth()].slice(0, 3)}, ${date.getFullYear()}`;
};

const emptyList = [];

const LatestReleases = ({location, data}) => {
  const [liveReleases, setLiveReleases] = useState(emptyList);
  const latestRelease = data.allRelease.nodes[0];
  const latestReleaseFrom =
    latestRelease && new Date(new Date(latestRelease.createdAtISO).getTime() + 1000);
  const relQuery =
    latestReleaseFrom &&
    `releases({"createdAt":{"op":"gt","value":"${latestReleaseFrom.toISOString()}"},"$limit":10,"$order":"-createdAt"})`;

  React.useEffect(() => {
    const query = relQuery && {
      _root: [{[relQuery]: ["id", "version", "content", "createdAt", "title"]}],
    };
    const stringQuery = JSON.stringify({query});
    fetch(`/`, {
      method: "POST",
      body: stringQuery,
    })
      .then(response => {
        const releases = response._root[relQuery].map(id => response.release[id]).filter(Boolean);
        if (releases.length) {
          return import("../lib/markdownToHtml.js").then(mod => ({
            lib: mod.default,
            releases,
          }));
        } else {
          return {lib: null, releases: emptyList};
        }
      })
      .then(({lib, releases}) => {
        if (lib) {
          const formatted = releases.map(({content, createdAt, ...rest}) => ({
            createdAt: formatDate(createdAt),
            content: {childMarkdownRemark: {asReact: lib(content)}},
            ...rest,
          }));
          setLiveReleases(formatted);
        } else {
          setLiveReleases(emptyList);
        }
      });
  }, [relQuery]);

  return (
    <Changelog
      pageContext={{
        years: [...data.years.distinct].sort().reverse(),
        releases: [...liveReleases, ...data.allRelease.nodes],
      }}
      location={location}
    />
  );
};

export default LatestReleases;

export const pageQuery = graphql`
  query LatestReleases {
    years: allRelease {
      distinct(field: year)
    }
    allRelease(limit: 20, sort: {fields: createdAt, order: DESC}) {
      nodes {
        id
        version
        createdAtISO: createdAt(formatString: "")
        createdAt(formatString: "DD MMM, YYYY")
        title
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
